import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'

import {
  FormCheckbox,
  FormText,
  useForm,
  Recaptcha,
  FormDropdown,
} from 'components/Forms'
import { validations } from 'utils/form-validation'
import absoluteUrl from 'utils/absoluteUrl'
import formatDate from 'utils/date'
import formOptionFormat from 'utils/formOptionFormat'
import colors from 'styles/colors'
import theme from 'styles/theme'
import GA4 from 'utils/GA4'
import {
  Actions,
  Center,
  Consent,
  FormColumn,
  Headline,
  SubmitBtn,
  Wrapper,
} from './NewVillageRegistration.styles'

const NewVillageRegistration = ({
  headline,
  firstNameLabel,
  lastNameLabel,
  emailLabel,
  zipCodeLabel,
  emailValidationMessage,
  consentLabel,
  consentValidationMessage,
  ctaLabel,
  locale,
  locOrigin,
  recaptchaKey,
  recordTypeValue,
  marketingConsentDateKey,
  marketingConsentGivenKey,
  countryOfResidenceOptions,
  countryOfResidenceLabel,
  countryValidationMessage,
  submitActionUrl,
  zipCodeValidationMessage,
  firstNameValidationMessage,
  lastNameValidationMessage,
  url,
  orgId,
  villageName,
}) => {
  const { notNull, validateEmail, lengthIsBetween } = validations()
  const [initalFormEvent, setInitalFormEvent] = useState(null)
  const firstNameValidation = (value) => {
    return lengthIsBetween(value, 1, 40)
  }

  const lastNameValidation = (value) => {
    return lengthIsBetween(value, 1, 80)
  }

  const emailValidation = (value) => {
    return validateEmail(value) && lengthIsBetween(value, 1, 80)
  }

  const fields = {
    firstName: {
      name: 'firstName',
      queryName: 'first_name',
      label: `${firstNameLabel} *`,
      validation: firstNameValidation,
      Component: FormText,
      errorMessage: firstNameValidationMessage,
      value: '',
      colorFont:
        villageName === 'Belmont Park Village'
          ? colors.bpvSandstone
          : undefined,
      colorBackground:
        villageName === 'Belmont Park Village' ? colors.bpvGreen : undefined,
    },
    lastName: {
      name: 'lastName',
      queryName: 'last_name',
      label: `${lastNameLabel} *`,
      validation: lastNameValidation,
      Component: FormText,
      value: '',
      errorMessage: lastNameValidationMessage,
      colorFont:
        villageName === 'Belmont Park Village'
          ? colors.bpvSandstone
          : undefined,
      colorBackground:
        villageName === 'Belmont Park Village' ? colors.bpvGreen : undefined,
    },
    email: {
      name: 'email',
      queryName: 'email',
      label: `${emailLabel} *`,
      value: '',
      errorMessage: emailValidationMessage,
      validation: emailValidation,
      Component: FormText,
      colorFont:
        villageName === 'Belmont Park Village'
          ? colors.bpvSandstone
          : undefined,
      colorBackground:
        villageName === 'Belmont Park Village' ? colors.bpvGreen : undefined,
    },
    zipCode: {
      name: 'zipCode',
      queryName: 'zip',
      label: `${zipCodeLabel} *`,
      errorMessage: zipCodeValidationMessage,
      value: '',
      Component: FormText,
      colorFont:
        villageName === 'Belmont Park Village'
          ? colors.bpvSandstone
          : undefined,
      colorBackground:
        villageName === 'Belmont Park Village' ? colors.bpvGreen : undefined,
    },
    country: {
      name: 'country',
      queryName: 'country',
      label: `${countryOfResidenceLabel} *`,
      value: '',
      Component: FormDropdown,
      options: formOptionFormat(countryOfResidenceOptions),
      errorMessage: countryValidationMessage,
      colorFont:
        villageName === 'Belmont Park Village'
          ? colors.bpvSandstone
          : undefined,
    },
  }

  const agreements = {
    consent: {
      name: 'consent',
      copy: consentLabel.childMarkdownRemark
        ? consentLabel.childMarkdownRemark.html
        : consentLabel,
      validation: notNull,
      errorMessage: consentValidationMessage,
    },
  }

  const { form, validateForm, handleChange, handleCheckbox } = useForm({
    ...fields,
    ...agreements,
  })

  const [verified, setVerified] = useState(false)
  const [triggerSubmit, setTriggerSubmit] = useState(false)
  const submitFormRef = useRef(null)
  const recaptchaRef = useRef(null)

  const submitFields = {
    encoding: 'UTF-8',
    retURL: absoluteUrl(url, locOrigin),
    [marketingConsentDateKey]: formatDate(),
    [marketingConsentGivenKey]: '1',
    recordType: recordTypeValue,
    '00N6700000AeZA0': 'Belmont Park',
    oid: orgId,
  }

  Object.keys(fields)
    .filter((key) => !!fields[key].queryName)
    .map((key) => {
      const { queryName } = fields[key]
      submitFields[queryName] = form[key].value
      return form[key].value
    })

  const submitForm = () => {
    if (validateForm() && submitFormRef.current && verified) {
      GA4('sign_up', {
        page_template_name: 'New Village Registration',
      })
      GA4('form_submit_custom', {
        page_template_name: 'New Village Registration',
        form_name: 'Registration',
      })

      submitFormRef.current.submit()
    }
  }

  const handleSubmit = () => {
    if (verified) {
      submitForm()
    } else {
      recaptchaRef.current.execute()
    }
  }

  const verifyRecaptcha = (response) => {
    if (response) {
      setVerified(true)
      setTriggerSubmit(true)
    } else {
      setVerified(false)
    }
  }
  const handleChangeGA4 = (e, name) => {
    if (initalFormEvent) {
      handleChange(e, name)
    } else {
      setInitalFormEvent(e)
      GA4('form_initiate', {
        page_template_name: 'New Village Registration',
        form_name: 'Registration',
        field_name: e.target.name,
      })
      handleChange(e, name)
    }
  }

  const handleBlur = (e) => {
    GA4('form_fallout', {
      page_template_name: 'New Village Registration',
      form_name: 'Registration',
      field_name: e.target.name,
    })
  }

  if (triggerSubmit) {
    submitForm()
    setTriggerSubmit(false)
  }
  const fieldsSplit = [
    Object.keys(fields).slice(0, 7),
    Object.keys(fields).slice(7),
  ]
  const colorBG =
    villageName === 'Belmont Park Village' && theme.colors.bpvGreen

  return (
    <Wrapper colorBG={colorBG}>
      <Center>
        {!!headline && <Headline>{headline}</Headline>}
        {fieldsSplit.map((half, i) => (
          <FormColumn key={`half${i.toString()}`}>
            {half.map((key, j) => {
              const { Component, value: initialValue, ...field } = fields[key]
              const { hasError, value } = form[key]
              return (
                <Component
                  {...field}
                  hasError={hasError}
                  value={value}
                  handleChange={handleChangeGA4}
                  handleBlur={handleBlur}
                  key={`${field.name}${j.toString()}`}
                />
              )
            })}
          </FormColumn>
        ))}

        <Consent>
          <FormCheckbox
            {...agreements.consent}
            hasError={form.consent.hasError}
            value={form.consent.value}
            handleChange={handleCheckbox}
            handleBlur={handleBlur}
            colorFont={
              villageName === 'Belmont Park Village'
                ? colors.bpvSandstone
                : undefined
            }
          />
        </Consent>
      </Center>
      <Actions>
        <form ref={submitFormRef} action={submitActionUrl} method="POST">
          {Object.keys(submitFields).map((key) => (
            <input
              type="hidden"
              name={key}
              value={submitFields[key]}
              key={key}
            />
          ))}
          <Recaptcha
            ref={recaptchaRef}
            sitekey={recaptchaKey}
            hl={locale}
            verifyCallback={verifyRecaptcha}
          />
          <SubmitBtn
            type="button"
            level={
              villageName === 'Belmont Park Village'
                ? 'secondaryBPV'
                : 'secondary'
            }
            width="100%"
            onClick={handleSubmit}>
            {ctaLabel}
          </SubmitBtn>
        </form>
      </Actions>
    </Wrapper>
  )
}

NewVillageRegistration.propTypes = {
  consentLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        html: PropTypes.string,
      }),
    }),
  ]),
  consentValidationMessage: PropTypes.string,
  ctaLabel: PropTypes.string,
  emailLabel: PropTypes.string,
  emailValidationMessage: PropTypes.string,
  firstNameLabel: PropTypes.string,
  headline: PropTypes.string,
  lastNameLabel: PropTypes.string,
  locale: PropTypes.string,
  recaptchaKey: PropTypes.string,
  zipCodeLabel: PropTypes.string,
  locOrigin: PropTypes.string,
  recordTypeValue: PropTypes.string,
  marketingConsentDateKey: PropTypes.string,
  marketingConsentGivenKey: PropTypes.string,
  submitActionUrl: PropTypes.string,
  url: PropTypes.string,
  zipCodeValidationMessage: PropTypes.string,
  firstNameValidationMessage: PropTypes.string,
  lastNameValidationMessage: PropTypes.string,
  orgId: PropTypes.string,
  countryOfResidenceOptions: PropTypes.arrayOf(PropTypes.string),
  countryOfResidenceLabel: PropTypes.string,
  countryValidationMessage: PropTypes.string,
  villageName: PropTypes.string,
}

NewVillageRegistration.defaultProps = {
  firstNameLabel: '',
  headline: '',
  lastNameLabel: '',
  locale: 'en',
  locOrigin: '',
  recaptchaKey: '',
  zipCodeLabel: '',
  emailLabel: '',
  consentLabel: '',
  ctaLabel: '',
  consentValidationMessage: '',
  emailValidationMessage: '',
  recordTypeValue: '',
  marketingConsentDateKey: '',
  marketingConsentGivenKey: '',
  submitActionUrl: '',
  zipCodeValidationMessage: '',
  firstNameValidationMessage: '',
  lastNameValidationMessage: '',
  url: '',
  orgId: '',
  countryOfResidenceOptions: [],
  countryOfResidenceLabel: '',
  countryValidationMessage: '',
  villageName: '',
}

export default NewVillageRegistration
