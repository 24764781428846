import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Hero from 'components/Hero'
import Layout from 'components/Layout'
import SectionHeader from 'components/SectionHeader'
import { Spacing100 } from 'styles/sharedStyle'
import GA4 from 'utils/GA4'

import { constructUrlPath } from 'utils/urlUtils'
import NewVillageRegistration from 'components/NewVillageRegistration'
import { fixAudiences } from 'utils/audiences'
import ArticleText from 'components/Article/ArticleText'

const renderComponents = (
  component,
  index,
  recaptchaKey,
  locale,
  url,
  locOrigin,
  formLabels,
  villageName
) => {
  switch (component.__typename) {
    case 'ContentfulCompSectionHeaderSch01':
      return (
        <SectionHeader
          key={`section-header-${index}`}
          eyebrow={component.eyebrow}
          title={component.headline}
          content={component.headerBodyCopy}
          ctaLabel={component.headerCtaLabel}
          ctaUrl={component.headerCtaUrl}
          audiences={fixAudiences(component.audiences)}
          villageName={villageName}
        />
      )
    case 'ContentfulCompBlockFreeTextBlk02':
      return (
        <ArticleText
          key={`articleText_${index}`}
          textType={component.textType}
          centered={component.centered}
          copy={component.textContent}
        />
      )
    case 'ContentfulLabelsNewVillageRegistrationLabelsLab10':
      return (
        <NewVillageRegistration
          key={`village-registration-${index}`}
          {...{ recaptchaKey, locale, url, ...formLabels }}
          locOrigin={locOrigin}
          villageName={villageName}
        />
      )
    default:
      return null
  }
}

const NewVillageRegistrationPageT10 = (props) => {
  const {
    pageContext: {
      recaptchaKey,
      nodeLocale,
      villageSlug,
      pageLevel,
      translatedSlugs,
      translatedServices,
    },
    data: {
      page: { formMetaData, hero, locale, jsonLdSchema, village },
    },
    location: { origin: locOrigin },
  } = props

  const formLabels = Object.assign(
    formMetaData &&
      formMetaData.find(
        (metaData) =>
          metaData.__typename ===
          'ContentfulLabelsNewVillageRegistrationLabelsLab10'
      )
  )

  useEffect(() => {
    GA4('form_view', {
      page_template_name: 'New Village Registration',
      form_name: 'Registration',
    })
  }, [])

  const media = hero && hero.media && hero.media[0]
  const hasHeroImage =
    (media && media.portrait && media.landscape) ||
    (media &&
      media.videoPlaceholder &&
      media.videoPlaceholder.landscape &&
      media.videoPlaceholder.portrait)
  const heroImage = hasHeroImage
    ? {
        portrait: media.portrait || media.videoPlaceholder.portrait,
        landscape: media.landscape || media.videoPlaceholder.landscape,
        altText:
          media.altText ||
          (media.videoPlaceholder ? media.videoPlaceholder.altText : ''),
      }
    : {}
  const hasHeroVideo = media && media.videoPortrait && media.videoLandscape
  const heroVideo = hasHeroVideo
    ? {
        portrait: media.videoPortrait,
        landscape: media.videoLandscape,
        opacity: media.opacity,
      }
    : {}
  const heroProps = hero
    ? {
        eyebrow: hero.eyebrow,
        content: hero.headline,
        image: heroImage,
        video: heroVideo,
        villageSlug:
          pageLevel === 'collection'
            ? 'The Bicester Collection'
            : village?.name,
      }
    : null

  const formDataNewVillage = formMetaData.find(
    (ele) =>
      ele.__typename === 'ContentfulLabelsNewVillageRegistrationLabelsLab10'
  )

  const url = formMetaData
    ? constructUrlPath({
        nodeLocale,
        pageLevel,
        translatedServices,
        translatedSlugs,
        url: formDataNewVillage ? formDataNewVillage.successUrl : '',
        villageSlug,
      })
    : ''

  return (
    <Layout {...props}>
      <Helmet>
        {jsonLdSchema && jsonLdSchema.jsonLdSchema ? (
          <script type="application/ld+json">
            {jsonLdSchema.jsonLdSchema}
          </script>
        ) : null}
      </Helmet>
      {heroProps && <Hero {...heroProps} />}
      <Spacing100 />
      {formMetaData &&
        formMetaData.map((component, index) =>
          renderComponents(
            component,
            index,
            recaptchaKey,
            locale,
            url,
            locOrigin,
            formLabels,
            village?.name
          )
        )}
    </Layout>
  )
}

NewVillageRegistrationPageT10.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.shape({
    origin: PropTypes.string,
  }).isRequired,
  pageContext: PropTypes.shape({
    recaptchaKey: PropTypes.string,
    nodeLocale: PropTypes.string,
    pageLevel: PropTypes.string,
    translatedServices: PropTypes.instanceOf(Object),
    translatedSlugs: PropTypes.instanceOf(Object),
    villageSlug: PropTypes.string,
  }).isRequired,
}

export const NewVillageRegistrationQuery = graphql`
  query(
    $id: String!
    $openingHoursId: String!
    $dateStart: Date!
    $dateEnd: Date!
    $nodeLocale: String!
    $pageTypeSlug: String
    $villageSlug: String
  ) {
    page: contentfulPageTemplateFormsT10(id: { eq: $id }) {
      jsonLdSchema {
        jsonLdSchema
      }
      id
      hero {
        eyebrow
        headline
        ...heroMediaQuery
      }
      locale: node_locale
      formMetaData {
        __typename
        ... on Node {
          ... on ContentfulCompSectionHeaderSch01 {
            eyebrow
            headline
            headerBodyCopy: bodyCopy {
              childMarkdownRemark {
                html
              }
            }
            headerCtaLabel: ctaLabel
            headerCtaUrl: ctaUrl
            audiences: childrenContentfulCompSectionHeaderSch01AudiencesJsonNode {
              content
            }
          }
          ... on ContentfulCompBlockFreeTextBlk02 {
            textType
            textContent {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulLabelsNewVillageRegistrationLabelsLab10 {
            headline
            firstNameLabel
            lastNameLabel
            zipCodeLabel
            emailLabel
            consentLabel {
              childMarkdownRemark {
                html
              }
            }
            ctaLabel
            firstNameValidationMessage
            lastNameValidationMessage
            countryOfResidenceOptions
            countryOfResidenceLabel
            countryValidationMessage
            zipCodeValidationMessage
            emailValidationMessage
            consentValidationMessage
            successUrl
            submitActionUrl
            marketingConsentGivenKey
            marketingConsentDateKey
            recordTypeValue
            orgId
          }
        }
      }
      village {
        name
        code
        currency
        emailRecipient
        villageSlug: slug
        servicesHeader {
          ...header
        }
        home: page_template_home_t01 {
          ...headerFooterVillage
        }
        openingHours {
          ...villageOpeningHours
        }
        defaultLocale
        openingStatusLabel
      }
      pageTitle
      pageDescription
      hideFromSearchEngine
    }
    openingHoursExceptions: allContentfulCompOpeningHoursExceptionOph04(
      filter: {
        date: { lte: $dateEnd, gte: $dateStart }
        comp_opening_hours_village_oph01: {
          elemMatch: { id: { eq: $openingHoursId } }
        }
      }
    ) {
      exceptions: edges {
        node {
          ...contentfulOpeningHoursExceptions
        }
      }
    }
    openingHoursLabels: allContentfulLabelOpeningHoursLab04(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          ...contentfulOpeningHoursLabels
        }
      }
    }
    labels: allContentfulLabelVillageLab09(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          visitBoutiqueLabel
        }
      }
    }
    memDaysOnboarding: contentfulCompMemDayOnb01(
      node_locale: { eq: $nodeLocale }
      village: { slug: { eq: $villageSlug } }
      pageTypes: { elemMatch: { slug: { eq: $pageTypeSlug } } }
    ) {
      ...memDaysOnboarding
    }
    allMemorableDays: allContentfulPageTemplateMemorableDaysT14(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { slug: { eq: $villageSlug } }
      }
    ) {
      edges {
        node {
          ...memDaysSlugs
        }
      }
    }
    allMembershipPopUps: allContentfulCompMembershipPopUp(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { slug: { eq: $villageSlug } }
        active: { eq: true }
      }
    ) {
      totalCount
      edges {
        node {
          ...membershipPopUp
        }
      }
    }
  }
`

export default NewVillageRegistrationPageT10
